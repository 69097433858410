import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import panel from './panel/panel.reducer';

const rootReducer = combineReducers({
  panel
});

const store = createStore(rootReducer, applyMiddleware(thunkMiddleware));

export default store;
