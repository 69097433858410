import jMoment from 'moment-jalaali';
import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PrivateRoute from './app/components/private-route/PrivateRoute';
import SplashScreen from './app/components/splash-screen/SplashScreen';
import { history } from './app/utilities/history';
import './scss/style.scss';

jMoment.loadPersian({ dialect: 'persian-modern', usePersianDigits: false });

const TheLayout = React.lazy(() => import('./containers/TheLayout'));
const Login = React.lazy(() => import('./app/views/auth/Login'));
const Logout = React.lazy(() => import('./app/views/auth/Logout'));

function App() {
  return (
    <Router history={history}>
      <React.Suspense fallback={<SplashScreen />}>
        <Switch>
          <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
          <Route exact path="/logout" name="Login Page" render={props => <Logout {...props} />} />
          <PrivateRoute path="/" name="Home" component={TheLayout} />
        </Switch>
        <ToastContainer
          position="top-center"
          autoClose={4000}
          hideProgressBar={true}
          newestOnTop
          closeOnClick
          rtl
          theme="colored"
        />
      </React.Suspense>
    </Router>
  );
}

export default App;
