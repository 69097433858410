import { memo } from 'react';

function SplashScreen() {
  return (
    <div id="splash-screen">
      <div className="d-flex min-vh-100 align-content-center align-items-center justify-content-center">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    </div>
  );
}

export default memo(SplashScreen);
